import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Cookies from 'js-cookie'
import ElementPlus from 'element-plus'
import { ElMessage } from 'element-plus'
import 'element-plus/dist/index.css'
import * as Icons from '@element-plus/icons'

const app = createApp(App)

Object.keys(Icons).forEach(key => {
  app.component(key, Icons[key])
})

app.use(router)
app.mount('#app')
app.use(ElementPlus)
// app.config.globalProperties.$message = ElMessage;

router.beforeEach((to, from, next) => {
  Cookies.set('from', from.path);
  let token = Cookies.get("authToken")
  if (to.path !== '/login') {
    console.log(to);
    if(to.query.id!= ''){
      Cookies.set('campId', to.query.id);
    }
    if (token == '' || (token === null) || (typeof token === "undefined")) {
      ElMessage.error('您的登录信息发生变化请重新登录')
      setTimeout(() => {
        next('/login')
      },500)
    } else {
      next();
    }
  } else {
    next();
  }
});
