import Vue from 'vue'
import { createRouter, createWebHistory } from 'vue-router'

// Vue.use(Router)
const routerHistory = createWebHistory();

const router =  createRouter({
  history: routerHistory,
  routes:
  [
    {
      path: '/login',
      name: '登录',
      component: () => import(/* webpackChunkName: "login" */ '../page/login.vue'),
    },
    {
      path: '/index',
      name: '成长记录',
      component: () => import(/* webpackChunkName: "index" */ '../page/index.vue'),
    },
    {
      path: '/',
      redirect: '/login',
      title: "营员成长记录",
      meta: {
        title: "营员成长记录"
      }
    }
  ]
})

export default router
